import usePipelineExecutionCheckpoints from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints';
import { CheckpointStatus } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import Loader from 'components/Loader/Loader';
import { ExecutionCheckpointProps } from './ExecutionCheckpoint.type';
import ExecutionCheckpointForm from './ExecutionCheckpointForm';
import { ExecutionCheckpointProvider } from './contexts/ExecutionCheckpointContext';

const ExecutionCheckpoint = ({
  pipelineExecutionId,
  completedCheckpoints,
  totalCheckpoints,
}: ExecutionCheckpointProps) => {
  const { checkpoints, areCheckpointsLoading } =
    usePipelineExecutionCheckpoints(pipelineExecutionId);
  const pendingCheckpoint = checkpoints.find(
    (checkpoint) => checkpoint.status === CheckpointStatus.PENDING,
  );

  return (
    <Loader loading={areCheckpointsLoading}>
      {pendingCheckpoint && (
        <ExecutionCheckpointProvider pipelineExecutionId={pipelineExecutionId}>
          <ExecutionCheckpointForm
            // The key is important in having the form re-render
            key={pendingCheckpoint.id}
            checkpoint={pendingCheckpoint}
            totalCheckpoints={totalCheckpoints}
            completedCheckpoints={completedCheckpoints}
          />
        </ExecutionCheckpointProvider>
      )}
    </Loader>
  );
};

export default ExecutionCheckpoint;
