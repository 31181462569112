import { Button, PaperProps, styled } from '@mui/material';
import RoundedPaper from 'components/RoundedPaper/RoundedPaper';

export const ExecutionCheckpointApprovalHeaderPaper = (props: PaperProps) => {
  return <RoundedPaper elevation={2} {...props} />;
};

export const Main = styled(RoundedPaper)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px;
  margin-bottom: 70px;
`;

export const FixedWidth = styled('div')`
  text-align: center;
  width: 120px;
`;

export const ExecutionCheckpointData = styled('div')`
  width: 100%;
`;

export const ExecutionCheckpointApproveButtonContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.surface.level1};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const ExecutionCheckpointApprovalHeaderLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const ExecutionCheckpointApproveButton = styled(Button)`
  border-radius: 1.5rem;
  width: 12rem;
`;

export const ExecutionCheckpointRejectButton = styled(Button)`
  border-radius: 1.5rem;
`;

export const ExecutionCheckpointForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const CheckpointApprovalHeaderPill = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.surface.level2};
  border-radius: 16px;
  padding: 8px 16px;
`;
