import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import PageNavigation from 'pages/ExecutionsPage/ExecutionsLayoutNavigation';
import { Outlet } from 'react-router-dom';

export default function ExecutionsPage() {
  return (
    <Layout>
      <PageNavigation />

      <ScrollContainer>
        <MuiContainer fixed>
          <Outlet />
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
