import { AssetObject, DataSchema, JobIOSchema } from '@pathways/pipeline-schema';
import { NodeType } from 'features/Flow/Flow.types';
import { Node, NodeProps } from 'reactflow';
import { FlowNode, FlowNodeData } from 'types/reactflow';

export type PathwayNodeProps = NodeProps<NodeData>;

export interface SignedAssetObject extends AssetObject {
  filename?: string;
  signedUrl: string;
}

export function isSignedAssetObject(value: unknown): value is SignedAssetObject {
  if (!value) return false;
  if (Array.isArray(value)) return false;
  if (typeof value !== 'object') return false;

  // To avoid `in` operators.
  const typedValue = value as Partial<SignedAssetObject>;

  // Ignore checking to see if signedUrl exists since static AssetInputs will not retain this in the pipeline content
  return !!typedValue.pathwayAssetId;
}

export function isSignedAssetObjectArray(value?: unknown): value is SignedAssetObject[] {
  return Array.isArray(value) && value.every(isSignedAssetObject);
}

export type NodeObjectValue = NonNullable<Record<string, unknown>>;

export type StaticValue =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | NodeObjectValue
  | NodeObjectValue[]
  | SignedAssetObject
  | SignedAssetObject[];

export interface DynamicValue {
  source: string;
}

export type NodeValue = StaticValue | DynamicValue;

export function isStaticValue(value?: NodeValue): value is StaticValue {
  if (value == null) return false;
  return !isDynamicValue(value);
}

export function isDynamicValue(value?: NodeValue): value is DynamicValue {
  if (!value) return false;

  return typeof value === 'object' && 'source' in value;
}

export interface NodeInputConfig {
  minimum?: number;
  maximum?: number;
  default?: number;
  required?: boolean;
  hidden?: boolean;
  forceHide?: boolean;
  hideConnectionStatus?: boolean;
}

export interface NodeInput {
  config: NodeInputConfig;
  dataSchema: DataSchema;
  description?: string;
  id?: string;
  name: string;
  title: string;
  value?: NodeValue;
}

export interface NodeOutputConfig {
  required?: boolean;
  forceHide?: boolean;
}

export interface NodeOutput {
  id?: string;
  name: string;
  dataSchema: DataSchema;
  value?: DynamicValue['source'];
  title: string;
  description?: string;
  config: NodeOutputConfig;
}

export type CustomNodeType =
  | NodeType.NEURON
  | NodeType.FUNCTION
  | NodeType.CHECKPOINT
  | NodeType.SUBPIPELINE;

export interface PathwayNodeMetadata {
  type: CustomNodeType;
  name: string;
  description: string;
  uses: string;
  customTitle?: string;
  variation?: string;
}

export interface NodeData extends FlowNodeData {
  metadata: PathwayNodeMetadata;
  schemas?: JobIOSchema;
  inputs: NodeInput[];
  outputs: NodeOutput[];
}

export interface PathwayNode extends Node<NodeData> {
  type: CustomNodeType;
}

export function isPathwayNode(node?: Pick<FlowNode, 'type'>): node is PathwayNode {
  const types: CustomNodeType[] = [
    NodeType.NEURON,
    NodeType.FUNCTION,
    NodeType.CHECKPOINT,
    NodeType.SUBPIPELINE,
  ];
  return types.some((type) => node?.type === type);
}
