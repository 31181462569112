import AddIcon from '@mui/icons-material/Add';
import { Button, Paper, Table, TableBody, TableCell, TableFooter } from '@mui/material';
import * as Styled from 'components/Table/Table.styles';
import { TableFooterRow } from 'components/Table/Table.styles';
import CustomTableHead from 'components/Table/TableHead';
import { getSelectAllState } from './DynamicTable.utils';
import DynamicTableRow from './DynamicTableRow';
import DynamicTableRowForm from './DynamicTableRowForm';
import useDynamicTable from './useDynamicTable';
import ReadOnlyTableRow from './ReadOnlyTableRow';

interface DynamicTableProps {
  objects: GenericObject[];
  selectable?: boolean;
  readOnly?: boolean;
  onChange?: (selectedRows: GenericObject[]) => void;
}

export type GenericObject = Record<string, unknown>;

const DynamicTable = ({ objects, selectable, readOnly, onChange }: DynamicTableProps) => {
  const {
    columns,
    displayRowForm,
    order,
    orderBy,
    selectedRows,
    tableRows,
    handleAddRow,
    handleRowFormCancel,
    handleRowFormConfirm,
    handleSelectAll,
    handleSelectRow,
    handleSort,
    handleRowEditConfirm,
  } = useDynamicTable({ objects, onChange });

  return (
    <Styled.TableContainer component={Paper} elevation={4}>
      <Table aria-label="objects">
        <CustomTableHead
          disabled={false}
          columns={columns}
          order={order}
          orderBy={orderBy}
          selectAllState={getSelectAllState(tableRows.length, selectedRows.length)}
          onSelectAll={!readOnly && selectable ? handleSelectAll : undefined}
          onSort={handleSort}
        />

        <TableBody>
          {tableRows.map((row) => {
            const rowKey = row._id;
            return readOnly ? (
              <ReadOnlyTableRow key={rowKey} row={row} columns={columns} />
            ) : (
              <DynamicTableRow
                key={rowKey}
                row={row}
                columns={columns}
                onSelectRow={selectable ? handleSelectRow : undefined}
                onConfirmEditRow={handleRowEditConfirm}
              />
            );
          })}
          {displayRowForm && (
            <DynamicTableRowForm
              columns={columns}
              onCancel={handleRowFormCancel}
              onConfirm={handleRowFormConfirm}
            />
          )}
        </TableBody>
        {!readOnly && (
          <TableFooter>
            <TableFooterRow>
              <TableCell colSpan={columns.length + 1}>
                <Button
                  color="inherit"
                  startIcon={<AddIcon />}
                  variant="text"
                  onClick={handleAddRow}
                >
                  Add new item
                </Button>
              </TableCell>
            </TableFooterRow>
          </TableFooter>
        )}
      </Table>
    </Styled.TableContainer>
  );
};

export default DynamicTable;
