import { Connection, Edge, Node, NodeProps } from 'reactflow';
import { NodeData, NodeOutput } from '../Node/Node.types';
import { PIPELINE_START_NODE_ID } from 'features/Flow/nodes/PipelineStart/PipelineStart.consts';
import { HandleConnection, NodeType } from 'features/Flow/Flow.types';
import { FlowNodeData, FlowNode } from 'types/reactflow';
import { CustomHandleData } from 'features/Flow/Handles/handle.store';

export type PipelineStartProps = NodeProps<PipelineStartData>;

export interface PipelineStartData extends FlowNodeData {
  metadata: {
    type: NodeType.PIPELINE_START;
  };
  /**
   * @deprecated Use `outputs` instead.
   */
  inputs?: never;
  outputs: NodeData['outputs'];
}

export interface PipelineStartNode extends Node<PipelineStartData> {
  type: NodeType.PIPELINE_START;
}

export function isPipelineStartNode(
  node?: Pick<FlowNode, 'id' | 'type'>,
): node is PipelineStartNode {
  return node?.id === PIPELINE_START_NODE_ID && node.type === NodeType.PIPELINE_START;
}

export interface StartAddHandle extends Omit<NodeOutput, 'config' | 'dataSchema'> {
  id: string;
  handle: CustomHandleData;
}

export interface StartOutput extends NodeOutput {
  id: string;
}

export type StartOutputConnection = HandleConnection;

export type StartOutputEdge = Edge<StartOutput> &
  StartOutputConnection & {
    data: StartOutput;
  };

export type NewStartOutputEdge = Omit<StartOutputEdge, 'id'> & {
  id?: StartOutputEdge['id'];
};

export function isStartOutputConnection(
  connection?: Connection | Edge,
): connection is StartOutputConnection {
  return connection?.source === PIPELINE_START_NODE_ID;
}

export function isStartOutputEdge(edge?: Edge<StartOutput>): edge is StartOutputEdge {
  return isStartOutputConnection(edge) && !!edge.data;
}
