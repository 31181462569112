import { IconButton, Typography } from '@mui/material';
import { getNodeOutput, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { createCustomHandle, useGetTargetHandle } from 'features/Flow/Handles/handles';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getConnectedEdges, useStore, useStoreApi, useUpdateNodeInternals } from 'reactflow';
import { FlowState } from 'types/reactflow';
import { shallow } from 'zustand/shallow';
import { PIPELINE_START_NAME } from './PipelineStart.consts';
import { NodeContainer, NodeDataRow, NodeDataColumn, NodeHeader } from '../Node/Node.styles';
import { PipelineStartProps, isPipelineStartNode } from './PipelineStart.types';
import SettingIcon from 'components/Icons/Setting';
import useFlow from 'features/Flow/hooks/useFlow';
import { usePipelineStartModal } from './PipelineStartModal/usePipelineStartModal';
import PipelineStartModal from './PipelineStartModal/PipelineStartModal';
import { NodeOutput } from '../Node/Node.types';
import NodeOutputHandle from 'features/Flow/nodes/Node/NodeOutputHandle';
import { canConnectToPipelineStart } from './PipelineStart.utils';
import { usePipelineStartQuickCreateModal } from './PipelineStartQuickCreateModal/usePipelineStartQuickCreateModal';
import PipelineStartQuickCreateModal from './PipelineStartQuickCreateModal/PipelineStartQuickCreateModal';
import { isHandleConnection } from 'features/Flow/Flow.types';
import { usePipelineStartOutputs } from './usePipelineStartOutputs';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';

const selector = ({ edges, mode }: FlowState) => ({
  edges,
  mode,
});

const PipelineStart = React.memo(function PipelineStart(props: PipelineStartProps) {
  const {
    id: nodeId,
    data: { outputs },
  } = props;
  const updateNodeInternals = useUpdateNodeInternals();
  const storeApi = useStoreApi();
  const { mode, edges } = useStore(selector, shallow);
  const { execution } = useGetNodeExecution(props);
  const { getNodeOrThrow } = useFlow();
  const node = useMemo(() => getNodeOrThrow(nodeId, isPipelineStartNode), [getNodeOrThrow, nodeId]);

  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { deferredConnection, setDeferredConnectionState, cancelDeferredConnectionAction } =
    useDeferredConnectionState({
      outputs,
    });

  const getTargetHandle = useGetTargetHandle();
  const { handleDisconnectedOutput, handleConnectedOutput, attemptAddOutput } =
    usePipelineStartOutputs({
      nodeId,
      outputs,
    });
  const nodeEdges = useMemo(() => getConnectedEdges([node], edges), [edges, node]);

  const addOutputHandle = useMemo(
    () => createCustomHandle(nodeId, 'output', canConnectToPipelineStart),
    [nodeId],
  );

  const createOutputFromModal = useCallback(
    (output: NodeOutput) => {
      if (!deferredConnection) {
        throw new Error('No deferred connection found.');
      }

      const newOutput = getNodeOutput(output);
      const isAdded = attemptAddOutput(newOutput, nodeId);

      if (!isAdded) {
        throw new Error('Output was not added.');
      }

      setDeferredConnectionState({
        connection: {
          ...deferredConnection,
          sourceHandle: newOutput.id,
        },
        outputName: newOutput.name,
      });
    },
    [attemptAddOutput, deferredConnection, nodeId, setDeferredConnectionState],
  );

  const pipelineStartModalState = usePipelineStartModal({ nodeId });
  const { openModal, ...quickCreateModal } = usePipelineStartQuickCreateModal({
    onCreateOutput: createOutputFromModal,
    onCloseModal: cancelDeferredConnectionAction,
  });

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addOutputHandle.id,
      nodeId,
    };

    addConnectionHandler?.(connectionHandlerKey, (connection, actionCallback) => {
      if (!isHandleConnection(connection)) return connection;

      const targetHandle = getTargetHandle(connection);

      const newOutput = getNodeOutput({
        config: targetHandle.config,
        dataSchema: targetHandle.schema,
        name: targetHandle.name,
        title: targetHandle.title,
        description: targetHandle.description,
      });
      const isAdded = attemptAddOutput(newOutput, nodeId);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...connection,
          sourceHandle: newOutput.id,
        },
      });

      if (isAdded) {
        setDeferredConnectionState({
          outputName: newOutput.name,
        });
      } else {
        // Open the modal for the user to finish creating the new output
        openModal(newOutput);
      }

      return 'defer';
    });
    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addOutputHandle.id,
    attemptAddOutput,
    getTargetHandle,
    nodeId,
    openModal,
    removeConnectionHandler,
    setDeferredConnectionState,
  ]);

  useEffect(() => {
    const startNode = getNodeOrThrow(nodeId, isPipelineStartNode);
    const pipelineStartOutputs = startNode.data.outputs.map(getNodeOutput);

    storeApi.setState(() => ({
      pipelineStartOutputs,
    }));

    pipelineStartOutputs.forEach((output) => {
      const outputEdges = nodeEdges.filter((edge) => edge.sourceHandle === output.id);
      const isOutputConnected = outputEdges.length > 0;
      if (isOutputConnected) {
        handleConnectedOutput(output, outputEdges[0]);
      } else {
        handleDisconnectedOutput(output);
      }
    });
  }, [
    getNodeOrThrow,
    handleConnectedOutput,
    handleDisconnectedOutput,
    nodeEdges,
    nodeId,
    storeApi,
  ]);

  useEffect(() => {
    updateNodeInternals(nodeId);
    // 'outputs' is a dependency here because we need to update the node internals when it change.
    outputs;
  }, [nodeId, outputs, updateNodeInternals]);

  return (
    <NodeContainer $isSelected={props.selected}>
      <NodeHeader>
        <Typography variant="labelLarge">{PIPELINE_START_NAME}</Typography>
        {mode === 'runtime' && (
          <StatusIcon status={execution?.status} color={({ palette }) => palette.text.primary} />
        )}
        {mode === 'editor' && (
          <IconButton onClick={pipelineStartModalState.openModal} data-testid="NodeSettingButton">
            <SettingIcon />
          </IconButton>
        )}
      </NodeHeader>

      <NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        <NodeDataColumn>
          {outputs.map((output) => (
            <NodeOutputHandle
              key={output.id ?? output.name}
              output={output}
              nodeId={nodeId}
              fullWidth
            />
          ))}

          {mode === 'editor' && (
            <CustomHandle fullWidth handle={addOutputHandle} title="Add data to collect..." />
          )}
        </NodeDataColumn>
      </NodeDataRow>

      {mode === 'editor' && pipelineStartModalState.open && (
        <PipelineStartModal outputs={outputs} {...pipelineStartModalState} />
      )}

      {mode === 'editor' && quickCreateModal.open && (
        <PipelineStartQuickCreateModal outputs={outputs} {...quickCreateModal} />
      )}
    </NodeContainer>
  );
});

export default PipelineStart;
