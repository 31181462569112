import { createHandleId } from 'features/Flow/Flow.utils';
import { CustomHandleData } from 'features/Flow/Handles/handle.store';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { isArrayDataSchema, isAssetDataSchema } from '@pathways/pipeline-schema/web';
import { isObjectDataSchema } from '../../Node/DataSchemas/objectSchema';
import { isTextDataSchema } from '../../Node/DataSchemas/textSchema';
import { NodeData } from '../../Node/Node.types';
import { ConnectedDataHandles, CustomAddHandles } from './DynamicCheckpoint';

export function createAddHandles(nodeId: string): CustomAddHandles {
  // For dynamic checkpoint, each handle pair must have the same name when approving on the execution.
  const name = nanoid(4);

  return {
    input: {
      id: createHandleId('input', name),
      name,
      nodeId,
      purpose: 'input',
      title: 'Data to Review',
      type: 'custom',
      canConnect,
    },
    output: {
      id: createHandleId('output', name),
      name,
      nodeId,
      purpose: 'output',
      title: 'Approved Data',
      type: 'custom',
      canConnect,
    },
  };
}

export const canConnect: CustomHandleData['canConnect'] = ({ schema }) => {
  const allowedTypes = [
    isTextDataSchema(schema),
    isObjectDataSchema(schema),
    isAssetDataSchema(schema),
    isArrayDataSchema(schema, 'text'),
    isArrayDataSchema(schema, 'object'),
    isArrayDataSchema(schema, 'image-uri'),
    isArrayDataSchema(schema, 'asset'),
  ];

  return allowedTypes.some(Boolean);
};

export function getConnectedDataHandles(connectedEdgesCount: number, data: NodeData) {
  const input = data.inputs.at(0);
  const output = data.outputs.at(0);

  if (isEmpty(input?.value) && !connectedEdgesCount) return undefined;

  if (!(input && output)) throw new Error('Input or Output data not found');

  return {
    input,
    output,
  } satisfies ConnectedDataHandles;
}
