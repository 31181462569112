import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import PanelCard from 'pages/RuntimePage/components/RightSidebar/components/PanelCard/PanelCard';
import { getExecutionStatus, isPipelineExecutionFinished } from 'utils/execution';

interface OutputTabPanelOutputProps {
  status?: ExecutionStatus;
  value?: unknown;
}

export default function OutputTabPanelOutput(props: OutputTabPanelOutputProps) {
  const { value } = props;
  const status = getExecutionStatus(props.status);
  const isFinished = isPipelineExecutionFinished(status);

  if (isFinished && value) {
    return <JsonEditor content={value} readOnly isArrayJson />;
  }

  return (
    <PanelCard
      title={<ClearAllRoundedIcon fontSize="large" color="action" />}
      description={
        <>
          Outputs will be displayed here when this
          <br />
          job successfully completes.
        </>
      }
    />
  );
}
