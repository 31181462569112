import { GlobalAppHeader } from '@monksflow/monks-ui-molecules';
import { HomeRounded } from '@mui/icons-material';
import { AppBar, Link, Stack, Typography } from '@mui/material';
import useWorkspaceProject from 'api/services/useWorkspaceProject/useWorkspaceProject';
import { RouterLink } from 'components/RouterLink';
import useAuthContext from 'contexts/auth/useAuthContext';
import useProjectId from 'hooks/useProjectId';
import useWorkspace from 'hooks/useWorkspace';
import { useMatch } from 'react-router-dom';
import { logout } from 'utils/logout';
import { getProjectName } from 'utils/project';
import { useAppRoutes } from 'utils/routes';
import * as Styled from './NavigationBar.styles';
import { ReactNode } from 'react';

interface NavigationBarProps {
  children?: React.ReactNode;
  title: string;
  firstBreadcrumb?: {
    label: string | ReactNode;
    route?: string;
  };
  lastBreadcrumb?: string;
  hideHome?: boolean;
}

export default function NavigationBar({
  children,
  title,
  firstBreadcrumb,
  lastBreadcrumb,
  hideHome = false,
}: NavigationBarProps) {
  const { user } = useAuthContext();
  const routes = useAppRoutes();
  const isDashboardRoute = useMatch(routes.dashboard());
  const workspace = useWorkspace();
  const projectId = useProjectId();
  const { workspaceProject } = useWorkspaceProject(workspace.id, projectId);
  const rootBreadcrumb = projectId ? getProjectName(workspaceProject?.name) : workspace.name;

  if (!user) throw new Error('User not found');

  return (
    <AppBar position="static">
      <GlobalAppHeader
        breadcrumbs={[
          {
            variant: 'text',
            label: rootBreadcrumb,
          },
          {
            variant: 'text',
            label: title,
            highlight: true,
          },
        ]}
        platformAppUrl={import.meta.env.MONKS_FLOW_PLATFORM_HOME_URL}
        user={user}
        workspaces={user.workspaces}
        selectWorkspace={(workspaceItem) => {
          if (workspaceProject) {
            return workspaceItem.id === workspaceProject.workspaceId;
          }

          return workspaceItem.id === workspace.id;
        }}
        onSignOutClick={logout}
      />
      {!isDashboardRoute && (
        <Styled.MuiToolbar>
          <Stack direction="row" gap={2} alignItems="center">
            {!hideHome && (
              <Link component={RouterLink} to={routes.dashboard()}>
                <Styled.BreadcrumbText $secondary display="flex">
                  <HomeRounded />
                </Styled.BreadcrumbText>
              </Link>
            )}

            <Styled.Breadcrumbs>
              {typeof firstBreadcrumb?.label === 'string' ? (
                <Link
                  title={firstBreadcrumb.label}
                  component={RouterLink}
                  to={firstBreadcrumb.route ?? ''}
                  underline="none"
                >
                  <Styled.BreadcrumbText variant="titleMedium" $secondary={!!lastBreadcrumb}>
                    {firstBreadcrumb.label}
                  </Styled.BreadcrumbText>
                </Link>
              ) : (
                firstBreadcrumb?.label
              )}

              {lastBreadcrumb && (
                <Styled.LastItem>
                  <Typography variant="titleMedium">{lastBreadcrumb}</Typography>
                </Styled.LastItem>
              )}
            </Styled.Breadcrumbs>
          </Stack>
          {children}
        </Styled.MuiToolbar>
      )}
    </AppBar>
  );
}
