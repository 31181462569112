import { useMemo } from 'react';
import { PathwayNodeProps } from '../../Node/Node.types';
import { createAddHandles, getConnectedDataHandles } from './DynamicCheckpoint.utils';

interface UseDynamicCheckpointProps extends Pick<PathwayNodeProps, 'id' | 'data'> {
  connectedEdgeCount: number;
}

export function useDynamicCheckpoint(props: UseDynamicCheckpointProps) {
  const { id: nodeId, data, connectedEdgeCount } = props;

  const customAddHandles = useMemo(() => createAddHandles(nodeId), [nodeId]);
  const connectedDataHandles = useMemo(
    () => getConnectedDataHandles(connectedEdgeCount, data),
    [data, connectedEdgeCount],
  );

  const displayDataHandles = !!connectedDataHandles;
  const displayAddHandles = !displayDataHandles;

  return {
    customAddHandles,
    connectedDataHandles,
    displayDataHandles,
    displayAddHandles,
  };
}
