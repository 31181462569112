import { Box, TextField } from '@mui/material';
import SingleElementActionsMenu from 'features/Flow/components/RightSidebar/components/SingleElementActionsMenu/SingleElementActionsMenu';
import TabContainer from 'components/TabContainer/TabContainer';
import * as Styled from './RightSidebar.styles';
import { RightSidebarProps } from './RightSidebar.types';
import Inputs from './components/Inputs/Inputs';
import Outputs from './components/Outputs/Outputs';
import { NodeType } from 'features/Flow/Flow.types';
import { isPathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { isPipelineCompleteNode } from 'features/Flow/nodes/PipelineComplete/PipelineComplete.types';
import { isPipelineStartNode } from 'features/Flow/nodes/PipelineStart/PipelineStart.types';
import { getJobTitle } from 'utils/neurons';
import { isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import GroupPanel from './components/GroupPanel/GroupPanel';
import { isBatchGroupNode } from 'features/Flow/nodes/Batch/Batch.types';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import StartOrCompletePanel from 'features/Flow/components/RightSidebar/components/StartOrCompletePanel/StartOrCompletePanel';
import PathwayNodePanelHeader from 'features/Flow/components/RightSidebar/components/PathwayNodePanelHeader/PathwayNodePanelHeader';

const RightSidebar = (props: RightSidebarProps) => {
  const { selectedNode, removeNode } = props;
  const updateNodeData = useUpdateNodeData();

  if (isGroupNode(selectedNode) || isBatchGroupNode(selectedNode)) {
    return <GroupPanel groupNode={selectedNode} />;
  }

  if (isPipelineStartNode(selectedNode) || isPipelineCompleteNode(selectedNode)) {
    return <StartOrCompletePanel selectedNode={selectedNode} />;
  }

  if (!isPathwayNode(selectedNode)) return null;

  return (
    <Box width="100%">
      <Styled.HeaderActionBar>
        <Styled.HeaderActionColumn>
          <PathwayNodePanelHeader selectedNode={selectedNode} />
        </Styled.HeaderActionColumn>
        <Styled.HeaderActionColumn>
          <SingleElementActionsMenu selectedNode={selectedNode} onRemoveNode={removeNode} />
        </Styled.HeaderActionColumn>
      </Styled.HeaderActionBar>

      <TextField
        name="customTitle"
        size="small"
        fullWidth
        label={selectedNode.type === NodeType.CHECKPOINT ? 'Checkpoint Name' : 'Neuron Name'}
        value={getJobTitle(selectedNode.data.metadata)}
        onChange={(event) => {
          updateNodeData(selectedNode.id, (data) => ({
            metadata: {
              ...data.metadata,
              customTitle: event.target.value,
            },
          }));
        }}
      />

      <Styled.SectionDivider />

      <TabContainer
        tabPanelChildren={[
          <Inputs
            inputs={selectedNode.data.inputs}
            key={selectedNode.id}
            selectedNode={selectedNode}
          />,
          <Outputs key={selectedNode.id} selectedNode={selectedNode} />,
        ]}
        tabs={['Input', 'Output']}
      />
    </Box>
  );
};

export default RightSidebar;
