import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import useCreatePipeline from 'api/services/useCreatePipeline/useCreatePipeline';
import usePipelines from 'api/services/usePipelines/usePipelines';
import useWorkspaceProject from 'api/services/useWorkspaceProject/useWorkspaceProject';
import SearchIcon from 'components/Icons/Search';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import SearchField from 'components/SearchField/SearchField';
import useToast from 'contexts/toast/useToast';
import FeatureFlag from 'features/FeatureFlag/FeatureFlag';
import { HandleStoreProvider } from 'features/Flow/Handles/handles';
import useProjectId from 'hooks/useProjectId';
import useWorkspace from 'hooks/useWorkspace';
import ImportPipeline from 'pages/DashboardPage/components/ImportPipeline/ImportPipeline';
import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProjectName } from 'utils/project';
import { useAppRoutes } from 'utils/routes';
import * as Styled from './DashboardPage.styles';
import NoPipelinesCreatedCard from './components/NoPipelinesCreatedCard/NoPipelinesCreatedCard';
import PipelineSearchNotFoundCard from './components/PipelineSearchNotFoundCard/PipelineSearchNotFoundCard';
import PipelineTableContainer from './components/PipelineTableContainer/PipelineTableContainer';

export default function DashboardPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const routes = useAppRoutes();
  const workspace = useWorkspace();
  const projectId = useProjectId();
  const { workspaceProject } = useWorkspaceProject(workspace.id, projectId);
  const projectName = getProjectName(workspaceProject?.name);
  const { createPipeline, isSaving } = useCreatePipeline();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const { debouncedSearchQuery, arePipelinesLoading, pipelines, pipelinesError, mutatePipelines } =
    usePipelines({
      workspaceId: workspace.id,
      projectId,
      searchQuery,
    });
  const displayPipelinesLoading = arePipelinesLoading;
  const displayPipelinesError = !!pipelinesError;
  const displayPipelines = displayPipelinesLoading || pipelines.length > 0 || displayPipelinesError;
  const displayPipelinesNotFound = !displayPipelines && debouncedSearchQuery.length > 0;
  const displayPipelinesNotCreated = !displayPipelines && !displayPipelinesNotFound;

  const handleSearchPipelines = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCreatePipeline = () => {
    createPipeline({ projectId, workspaceId: workspace.id })
      .then((response) => {
        navigate(routes.pipelines(response.data.id));
      })
      .catch((error: Error) => {
        toast.error({
          message: `Something went wrong. ${error.message}`,
        });
      });
  };

  return (
    <>
      <NavigationBar title="Pipelines" firstBreadcrumb={{ label: 'Pipelines' }} />

      <Styled.MuiContainer>
        <Styled.PipelinesListContainer>
          <Grid container>
            <Grid
              item
              gap={2}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                width: '100%',
              }}
            >
              <>
                <Styled.PathwaysProjectIcon />
                <Typography variant="titleLarge">{projectName}</Typography>
              </>
            </Grid>

            <Grid item xs={4} textAlign="start" mt={3} mb={3}>
              {!displayPipelinesNotCreated && (
                <SearchField
                  autoComplete="off"
                  fullWidth
                  onChange={handleSearchPipelines}
                  placeholder="Search..."
                  value={searchQuery}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={8} textAlign="end" mt="24px" mb="24px">
              <Stack direction="row" justifyContent="flex-end">
                <FeatureFlag feature="pipelineExport">
                  <ImportPipeline />
                </FeatureFlag>
                <Styled.CreatePipelineButton
                  startIcon={!isSaving ? <AddIcon /> : null}
                  variant="contained"
                  disabled={isSaving}
                  onClick={handleCreatePipeline}
                >
                  {isSaving ? (
                    <CircularProgress
                      color="warning"
                      size={18}
                      sx={(theme) => ({
                        color: theme.palette.action.disabled,
                      })}
                    />
                  ) : (
                    'Create Pipeline'
                  )}
                </Styled.CreatePipelineButton>
              </Stack>
            </Grid>
          </Grid>
          {displayPipelines && (
            <HandleStoreProvider>
              <PipelineTableContainer
                loading={displayPipelinesLoading}
                loadingFailed={displayPipelinesError}
                pipelines={pipelines}
                mutatePipelines={mutatePipelines}
              />
            </HandleStoreProvider>
          )}
          {displayPipelinesNotFound && (
            <PipelineSearchNotFoundCard searchQuery={debouncedSearchQuery} />
          )}
          {displayPipelinesNotCreated && <NoPipelinesCreatedCard />}
        </Styled.PipelinesListContainer>
      </Styled.MuiContainer>
    </>
  );
}
