import { SHORTCUT_CONFIG } from 'config/shortcut';
import useFlow from 'features/Flow/hooks/useFlow';
import useGroupChildNodes from 'features/Flow/hooks/useGroupChildNodes';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import {
  Dimensions,
  NodePositionChange,
  NodeProps,
  getNodesBounds,
  useKeyPress,
  useStoreApi,
} from 'reactflow';
import * as Styled from './GroupContainer.styles';

export const GROUP_CONTAINER_PADDING_PX = 16;
const STYLE_PADDING = GROUP_CONTAINER_PADDING_PX * 2;

interface GroupContainerProps extends NodeProps {
  children: ReactNode;
  color: string;
  extraHeight?: number;
}

export default function GroupContainer(props: GroupContainerProps) {
  const { children, id, xPos, yPos, dragging, color, selected } = props;

  const extraHeight = props.extraHeight ?? 0;

  const [dimensions, setDimensions] = useState<Dimensions>({
    height: 0,
    width: 0,
  });

  const storeApi = useStoreApi();
  const { deleteElements, getNodes, getNode, getNodeOrThrow } = useFlow();
  const childNodes = useGroupChildNodes(id);
  const hasChildNodes = childNodes.length > 0;
  const isChildDragging = childNodes.some((node) => node.dragging);
  const allChildNodesSelected = childNodes.every((node) => node.selected);

  const updatePosition = useCallback(() => {
    const groupPosition = getNodesBounds(childNodes);
    const groupNode = getNode(id);
    const groupFixedPosition = {
      x: groupPosition.x - GROUP_CONTAINER_PADDING_PX,
      y: groupPosition.y - GROUP_CONTAINER_PADDING_PX,
    };

    if (
      groupNode?.position.x === groupFixedPosition.x &&
      groupNode.position.y === groupFixedPosition.y
    ) {
      return;
    }

    const positionChanges = getNodes().reduce<NodePositionChange[]>((changes, node) => {
      if (node.id === id) {
        changes.push({
          type: 'position',
          id: node.id,
          position: {
            x: groupFixedPosition.x,
            y: groupFixedPosition.y,
          },
        });
      }

      if (childNodes.some((childNode) => childNode.id === node.id)) {
        const childPosition = node.positionAbsolute ?? node.position;

        changes.push({
          type: 'position',
          id: node.id,
          position: {
            x: childPosition.x - groupPosition.x + GROUP_CONTAINER_PADDING_PX,
            y: childPosition.y - groupPosition.y + GROUP_CONTAINER_PADDING_PX,
          },
        });
      }

      return changes;
    }, []);

    storeApi.getState().onNodesChange?.(positionChanges);
  }, [childNodes, getNode, getNodes, id, storeApi]);

  const ungroupPressed = useKeyPress(SHORTCUT_CONFIG.ungroupDrag.keyCode, {
    target: document.body,
  });

  const getChildNodesBounds = useCallback(() => {
    if (isChildDragging && ungroupPressed) {
      return getNodesBounds(childNodes.filter((node) => !node.dragging));
    }

    return getNodesBounds(childNodes);
  }, [childNodes, isChildDragging, ungroupPressed]);

  useLayoutEffect(() => {
    if (dragging) return;

    const childNodesBounds = getChildNodesBounds();

    setDimensions(childNodesBounds);
    updatePosition();
  }, [getChildNodesBounds, dragging, id, xPos, yPos, updatePosition]);

  useEffect(() => {
    if (hasChildNodes) return;

    deleteElements({
      nodes: [{ id }],
    });
  }, [deleteElements, hasChildNodes, id]);

  useLayoutEffect(() => {
    const { userSelectionActive, unselectNodesAndEdges } = storeApi.getState();

    // Only when the selection box is active (click and drag to select nodes).
    if (!userSelectionActive) return;

    if (!allChildNodesSelected && selected) {
      const node = getNodeOrThrow(id);

      unselectNodesAndEdges({
        nodes: [node],
      });
    }
  }, [allChildNodesSelected, getNodeOrThrow, id, selected, storeApi]);

  const displayNode = hasChildNodes && dimensions.height > 0 && dimensions.width > 0;

  if (!displayNode) return null;

  return (
    <Styled.Container
      data-group-id={id}
      color={color}
      $extraMargin={extraHeight}
      selected={selected}
      style={{
        /**
         * Using style instead of sx to avoid warning for too many classes being added on one component.
         * This happens when @mui/material/Box component have a large number of prop permutations.
         */
        height: dimensions.height + STYLE_PADDING + extraHeight,
        width: dimensions.width + STYLE_PADDING,
      }}
    >
      {children}
    </Styled.Container>
  );
}
