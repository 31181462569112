import ActionsMenu from 'components/ActionsMenu/ActionsMenu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ShortcutCaption from 'components/ShortcutCaption/ShortcutCaption';
import useAddGroupNode from 'features/Flow/hooks/useAddGroupNode';
import useCopyElementsToClipboard from 'features/Flow/hooks/useCopyElementsToClipboard';
import useRemoveNodesFromGroup from 'features/Flow/hooks/useRemoveNodeFromGroup';
import { Button, ListItemText, MenuItem } from '@mui/material';
import { FlowNode } from 'types/reactflow';
import { ReactElement, useEffect } from 'react';
import { SHORTCUT_CONFIG } from 'config/shortcut';
import { isGroupChildNode, isGroupNode } from 'features/Flow/nodes/Group/Group.types';
import { useExecuteOnce } from 'hooks/useExecuteOnce';
import { useKeyPress } from 'reactflow';
import useFlow from 'features/Flow/hooks/useFlow';
import { isBatchChildNode } from 'features/Flow/nodes/Batch/Batch.types';
import { isSubpipelineNode } from 'features/Flow/nodes/Subpipeline/Subpipeline.types';
import { useAppRoutes } from 'utils/routes';
import { useTheme } from 'styled-components';

interface SingleElementActionsMenuProps {
  selectedNode: FlowNode;
  onRemoveNode: () => void;
}

const SingleElementActionsMenu = ({
  selectedNode,
  onRemoveNode,
}: SingleElementActionsMenuProps): ReactElement => {
  const theme = useTheme();
  const appRoutes = useAppRoutes();
  const addGroupNode = useAddGroupNode();
  const removeNodesFromGroup = useRemoveNodesFromGroup();

  const copyElementsToClipboard = useCopyElementsToClipboard();
  const isGroupSelected = isGroupNode(selectedNode);
  const { wasExecutedOnce: wasShortcutTriggered, setExecutedOnce: setShortcutTriggered } =
    useExecuteOnce();

  const { getNodes } = useFlow();
  const nodes = getNodes();

  const isChildNode = !!selectedNode.parentNode;
  const isGroupChildNodeSelected = isGroupChildNode(selectedNode, nodes);
  const isBatchChildNodeSelected = isBatchChildNode(selectedNode, nodes);
  const isSubpipelineNodeSelected = isSubpipelineNode(selectedNode);

  const createGroupPressed = useKeyPress(SHORTCUT_CONFIG.createGroup.keyCode, {
    target: document.body,
  });

  const ungroupPressed = useKeyPress(SHORTCUT_CONFIG.ungroup.keyCode, {
    target: document.body,
  });

  useEffect(() => {
    if (wasShortcutTriggered || isGroupSelected || !createGroupPressed || isBatchChildNodeSelected)
      return;
    setShortcutTriggered(true);

    addGroupNode([selectedNode]);
  }, [
    addGroupNode,
    createGroupPressed,
    isBatchChildNodeSelected,
    isGroupSelected,
    selectedNode,
    setShortcutTriggered,
    wasShortcutTriggered,
  ]);

  useEffect(() => {
    if (wasShortcutTriggered || !ungroupPressed) return;
    setShortcutTriggered(true);

    if (isBatchChildNodeSelected || isGroupChildNodeSelected) {
      removeNodesFromGroup([selectedNode]);
    }
  }, [
    isBatchChildNodeSelected,
    isGroupChildNodeSelected,
    removeNodesFromGroup,
    selectedNode,
    setShortcutTriggered,
    ungroupPressed,
    wasShortcutTriggered,
  ]);

  return (
    <ActionsMenu
      renderButton={(buttonProps) => (
        <Button {...buttonProps} endIcon={<ArrowDropDownIcon />} variant="outlined">
          Actions
        </Button>
      )}
      renderActions={(closeMenu) => {
        const actions = [
          <MenuItem
            key="copy"
            onClick={() => {
              closeMenu();
              void copyElementsToClipboard();
            }}
          >
            <ListItemText>Copy</ListItemText>
            <ShortcutCaption>{SHORTCUT_CONFIG.copy.caption}</ShortcutCaption>
          </MenuItem>,
        ];

        if (!isChildNode) {
          actions.push(
            <MenuItem
              key="createGroup"
              onClick={() => {
                closeMenu();
                addGroupNode([selectedNode]);
              }}
            >
              <ListItemText>Create Group</ListItemText>
              <ShortcutCaption>{SHORTCUT_CONFIG.createGroup.caption}</ShortcutCaption>
            </MenuItem>,
          );
        } else if (isGroupChildNodeSelected) {
          actions.push(
            <MenuItem
              key="removeFromGroup"
              onClick={() => {
                closeMenu();
                removeNodesFromGroup([selectedNode]);
              }}
            >
              <ListItemText>Remove From Group</ListItemText>
              <ShortcutCaption>{SHORTCUT_CONFIG.ungroup.caption}</ShortcutCaption>
            </MenuItem>,
          );
        } else if (isBatchChildNodeSelected) {
          actions.push(
            <MenuItem
              key="removeFromBatch"
              onClick={() => {
                closeMenu();
                removeNodesFromGroup([selectedNode]);
              }}
            >
              <ListItemText>Remove From Batch</ListItemText>
              <ShortcutCaption>{SHORTCUT_CONFIG.ungroup.caption}</ShortcutCaption>
            </MenuItem>,
          );
        }

        isSubpipelineNodeSelected &&
          actions.push(
            <MenuItem
              key="openSubpipelineInEditor"
              sx={{
                borderTop: `1px solid ${theme.palette.surface.dividerLight}`,
                borderBottom: `1px solid ${theme.palette.surface.dividerLight}`,
              }}
              onClick={() => {
                const subpipelineRoute = `${window.location.origin}${appRoutes.pipelineVersion(
                  selectedNode.data.pipeline.id,
                  selectedNode.data.pipeline.versionId,
                )}`;
                window.open(subpipelineRoute);
                closeMenu();
              }}
            >
              Open Pipeline
            </MenuItem>,
          );

        if (selectedNode.deletable ?? true) {
          actions.push(
            <MenuItem
              key="deleteNode"
              onClick={() => {
                closeMenu();
                onRemoveNode();
              }}
            >
              <ListItemText
                sx={{
                  color: 'error.main',
                }}
              >
                Delete
              </ListItemText>
              <ShortcutCaption>{SHORTCUT_CONFIG.delete.caption}</ShortcutCaption>
            </MenuItem>,
          );
        }

        return actions;
      }}
    />
  );
};

export default SingleElementActionsMenu;
