import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { NodeOutput } from '../../../Node/Node.types';
import { Grid, IconButton, Typography } from '@mui/material';
import * as Styled from './ObjectExtractorModal.styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';

interface SortableNodeOutputProps {
  output: NodeOutput;
  index: number;
  isDragging: boolean;
  onEditOutput: (output: NodeOutput, position: number) => void;
  onRemoveOutput: (index: number) => void;
}

const SortableNodeOutput = ({
  index,
  output,
  isDragging,
  onEditOutput,
  onRemoveOutput,
}: SortableNodeOutputProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: output.name,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Grid container alignItems="center">
        <Grid item xs={1} display="flex" alignItems="center">
          <Styled.DragIcon {...listeners} $isDragging={isDragging} />
        </Grid>
        <Grid item xs={1}>
          <DataSchemaIcon dataSchema={output.dataSchema} size={'24px'} />
        </Grid>
        <Grid item xs={8}>
          <Typography>{output.title || output.name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              onEditOutput(output, index);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              onRemoveOutput(index);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SortableNodeOutput;
