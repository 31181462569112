import { Typography } from '@mui/material';
import CopyButton from 'components/CopyButton/CopyButton';

interface PipelineIdFieldWithCopyActionProps {
  pipelineId?: string;
}

const PipelineIdFieldWithCopyAction = ({ pipelineId }: PipelineIdFieldWithCopyActionProps) => {
  return (
    <>
      <Typography color={'text.secondary'} variant="labelSmall" sx={{ mb: 1 }}>
        Pipeline ID
      </Typography>
      <CopyButton label={pipelineId} size="small" value={String(pipelineId)} />
    </>
  );
};

export default PipelineIdFieldWithCopyAction;
