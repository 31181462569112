import { Theme, Typography } from '@mui/material';
import { ExecutionStatus } from 'api/services/usePipelineExecutions/usePipelineExecutions.types';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { FlowExecutionJob } from 'types/reactflow';
import { getJobTitle } from 'utils/neurons';
import * as Styled from '../Node.styles';
import { PathwayNodeMetadata } from '../Node.types';

const headerIconErrorStyles = {
  color: (theme: Theme) => theme.palette.error.main,
  backgroundColor: 'white',
  borderRadius: '100%',
};

interface NodeHeaderProps {
  metadata: PathwayNodeMetadata;
  execution?: FlowExecutionJob;
  icon?: React.ReactNode;
}

export const NodeHeader: React.FC<NodeHeaderProps> = ({ execution, metadata, icon }) => {
  return (
    <Styled.NodeHeader $nodeType={metadata.type}>
      <Typography variant="labelLarge">{getJobTitle(execution ?? metadata)}</Typography>

      {icon ?? (
        <StatusIcon
          status={execution?.status}
          sx={execution?.status === ExecutionStatus.FAILED ? headerIconErrorStyles : undefined}
        />
      )}
    </Styled.NodeHeader>
  );
};
