import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import JsonEditor from 'components/JsonEditor/JsonEditor';
import { useState } from 'react';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import * as Styled from '../ExecutionCompleted/ExecutionCompleted.styles';
import CopyButton from 'components/CopyButton/CopyButton';

interface ExecutionObjectListViewProps {
  title: string;
  data: Record<string, unknown>[];
}

const ExecutionObjectListView: React.FC<ExecutionObjectListViewProps> = ({ title, data }) => {
  const [view, setView] = useState<'table' | 'json'>('table');

  return (
    <>
      <Styled.ContainedLabel variant="labelSmall" color="text.secondary">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {title}

          <Box>
            <FormControlLabel
              control={
                <Switch
                  data-testid="ViewAsJsonSwitch"
                  checked={view === 'json'}
                  onClick={() => {
                    setView((prev) => (prev === 'table' ? 'json' : 'table'));
                  }}
                />
              }
              label={<Typography variant="bodyMedium">View as JSON</Typography>}
            />
            <CopyButton
              value={JSON.stringify(data, null, 2)}
              sx={{
                my: -1,
                mr: 2,
              }}
            />
          </Box>
        </Stack>
      </Styled.ContainedLabel>
      {view === 'table' && (
        <Box mt={1}>
          <DynamicTable objects={data} readOnly />
        </Box>
      )}
      {view === 'json' && <JsonEditor content={{ json: data }} readOnly isArrayJson />}
    </>
  );
};

export default ExecutionObjectListView;
