import { ListPipelineVersionsResponse } from 'api/services/usePipelineVersions/usePipelineVersions.types';
import { useRequest } from 'api/client';

const usePipelineVersions = (pipelineId: string) => {
  const {
    isLoading: isFetchingVersions,
    isValidating,
    data,
    error,
    mutate,
  } = useRequest<ListPipelineVersionsResponse>({
    url: `/pipelines/${pipelineId}/versions`,
    method: 'GET',
  });

  return {
    arePipelineVersionsLoading: isFetchingVersions || isValidating,
    pipelineVersionsData: data,
    pipelineVersionsError: error,
    getPipelineVersions: mutate,
  };
};

export default usePipelineVersions;
