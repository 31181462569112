import { CheckCircle } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import * as Styled from './ExecutionCheckpoint.styles';
import ExecutionCheckpointRejectModal from './modals/ExecutionCheckpointRejectModal/ExecutionCheckpointRejectModal';
import { ExecutionCheckpointRejectFormValues } from './modals/ExecutionCheckpointRejectModal/ExecutionCheckpointRejectModal.types';

interface ExecutionCheckpointActionFooterProps {
  isWaitingExecutionRefresh: boolean;
  isRejectCheckpointPending: boolean;
  onReject: SubmitHandler<ExecutionCheckpointRejectFormValues>;
}

const ExecutionCheckpointActionFooter = ({
  children,
  isWaitingExecutionRefresh,
  isRejectCheckpointPending,
  onReject,
}: PropsWithChildren<ExecutionCheckpointActionFooterProps>) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const { formState } = useFormContext();
  const { isValid } = formState;

  return (
    <Styled.ExecutionCheckpointApproveButtonContainer data-testid="ExecutionCheckpointActionFooter">
      <div>{children}</div>
      <Stack direction="row" spacing={2}>
        <Styled.ExecutionCheckpointRejectButton
          color="error"
          disabled={isWaitingExecutionRefresh || isRejectCheckpointPending}
          variant="outlined"
          onClick={() => {
            setIsRejectModalOpen(true);
          }}
        >
          Reject
        </Styled.ExecutionCheckpointRejectButton>
        <Styled.ExecutionCheckpointApproveButton
          color="primary"
          disabled={!isValid || isWaitingExecutionRefresh || isRejectCheckpointPending}
          type="submit"
          variant="contained"
          startIcon={<CheckCircle />}
        >
          Approve & Continue
        </Styled.ExecutionCheckpointApproveButton>
      </Stack>
      <ExecutionCheckpointRejectModal
        isOpen={isRejectModalOpen}
        isLoading={isRejectCheckpointPending || isWaitingExecutionRefresh}
        onClose={() => {
          setIsRejectModalOpen(false);
        }}
        onReject={onReject}
      />
    </Styled.ExecutionCheckpointApproveButtonContainer>
  );
};

export default ExecutionCheckpointActionFooter;
