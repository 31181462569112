import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ButtonProps, IconButton, Menu } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ActionsMenuProps } from './ActionsMenu.types';

export default function ActionsMenu(props: ActionsMenuProps) {
  const { baseId = 'actions', renderButton, renderActions, ...menuProps } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = !!anchorEl;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuId = `${baseId}-menu`;
  const buttonId = `${baseId}-button`;
  const buttonProps = {
    'aria-controls': open ? menuId : undefined,
    'aria-haspopup': 'true',
    'aria-expanded': open ? 'true' : undefined,
    id: buttonId,
    size: 'small',
    onClick: handleClick,
  } satisfies ButtonProps;

  return (
    <>
      {renderButton ? (
        renderButton(buttonProps, open)
      ) : (
        <IconButton {...buttonProps} data-testid="ActionsMenuButton">
          <MoreHorizIcon />
        </IconButton>
      )}
      <Menu
        id={menuId}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': buttonId,
          sx: (theme) => ({
            boxShadow: theme.shadows[8],
            '.MuiMenuItem-divider': {
              borderBottomColor: theme.palette.surface.dividerMain,
            },
          }),
        }}
        onClose={handleClose}
        {...menuProps}
      >
        {renderActions(handleClose)}
      </Menu>
    </>
  );
}
