import { DataSchema } from '@pathways/pipeline-schema';
import { isIntegerDataSchema } from 'features/Flow/nodes/Node/DataSchemas/integerSchema';
import { isNumberDataSchema } from 'features/Flow/nodes/Node/DataSchemas/numberSchema';
import { ColumnConfig, SelectAllState } from 'components/Table/TableHead';
import { RHFTextFieldProps } from 'components/ui/rhf-components/RHFTextField/RHFTextField.types';
import { nanoid } from 'nanoid';
import { GenericObject } from './DynamicTable';
import { Row } from './useDynamicTable';

export const CELL_MAX_WIDTH = 500;

export function getParsedRows(objects: GenericObject[], columns: ColumnConfig[]): Row[] {
  return objects.map((object) => parseRow(object, columns));
}

export function parseRow(object: GenericObject, columns: ColumnConfig[]): Row {
  const parsedRow: Row = {
    _id: nanoid(8),
    _selected: false,
  };

  for (const column of columns) {
    parsedRow[column.id] = parseRowDataValue(object, column.id);
  }

  return parsedRow;
}

export function parseRowDataValue(object: GenericObject, key: string): unknown {
  const value = object[key];
  if (value === null || value === undefined) {
    return null;
  }

  if (
    typeof value === 'boolean' ||
    typeof value === 'object' ||
    Array.isArray(value) ||
    typeof value === 'string' ||
    typeof value === 'number'
  ) {
    return value;
  }

  return String(value);
}

export function getAllObjectKeys(objects: GenericObject[]): string[] {
  const keys = objects.reduce<string[]>((acc, object) => {
    const objectKeys = Object.keys(object);
    return [...acc, ...objectKeys];
  }, []);

  return Array.from(new Set(keys));
}

export function getDataSchemaByValue(value: unknown): DataSchema | undefined {
  const valueType = typeof value;

  if (value == null) {
    return undefined;
  }

  if (valueType === 'string') {
    return { type: 'text' };
  }

  if (valueType === 'boolean') {
    return { type: 'boolean' };
  }

  if (valueType === 'bigint' || valueType === 'number') {
    if (Number.isInteger(value)) {
      return { type: 'integer' };
    }
    return { type: 'number' };
  }

  if (valueType === 'object') {
    if (Array.isArray(value)) {
      const arrayItemSchema = getDataSchemaByValue(value[0]);

      return {
        type: 'array',
        items: arrayItemSchema ?? { type: null },
      };
    }

    return { type: 'object' };
  }

  // If not matched.
  return undefined;
}

export function getSelectAllState(totalRowCount: number, selectedRowCount: number): SelectAllState {
  if (selectedRowCount === 0) {
    return 'none';
  }

  if (selectedRowCount === totalRowCount) {
    return 'all';
  }

  return 'partial';
}

export function getInlineInputValueAs(schema?: DataSchema): RHFTextFieldProps['valueAs'] {
  if (isIntegerDataSchema(schema)) {
    return 'integer';
  }
  if (isNumberDataSchema(schema)) {
    return 'number';
  }
  return undefined;
}

export function getNotSupportedValueProps(valueType: string) {
  const isValueNotSupported = ['object'].includes(valueType);
  return isValueNotSupported
    ? ({
        disabled: true,
        placeholder: 'Not supported value',
        TooltipProps: {
          title: 'This field can be edited as json.',
        },
      } satisfies Partial<RHFTextFieldProps>)
    : undefined;
}
