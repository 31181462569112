import { BATCH_NODE_COLOR } from 'features/Flow/nodes/Batch/Batch.consts';
import GroupContainer from 'components/GroupContainer/GroupContainer';
import GroupName from 'components/GroupContainer/GroupName';
import { NodeProps } from 'reactflow';
import { BatchGroupData } from './Batch.types';

type BatchGroupProps = NodeProps<BatchGroupData>;

export default function BatchGroup(props: BatchGroupProps) {
  const { data } = props;

  return (
    <GroupContainer {...props} color={BATCH_NODE_COLOR}>
      <GroupName label={data.metadata.name || 'Batch'} />
    </GroupContainer>
  );
}
