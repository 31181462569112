import { Popper, tooltipClasses } from '@mui/material';
import styled, { css } from 'styled-components';

export const MuiPopper = styled(Popper)(
  ({ theme }) => css`
    ${`&& .${tooltipClasses.tooltip}`} {
      background-color: ${theme.palette.surface.level4};
      padding: ${theme.spacing(1.5)};
      margin-top: ${theme.spacing(0.5)};
    }
  `,
);
