import { Box, Popover } from '@mui/material';
import { useState, MouseEvent, ReactElement } from 'react';

interface InfoPopoverProps {
  hoverTarget: ReactElement;
  content: ReactElement;
}

const InfoPopover = ({ hoverTarget, content }: InfoPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = 'info-popover';

  return (
    <>
      <Box
        aria-haspopup={true}
        aria-owns={open ? popoverId : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{ alignItems: 'center', display: 'flex', cursor: 'pointer' }}
      >
        {hoverTarget}
      </Box>
      <Popover
        anchorEl={anchorEl}
        disableRestoreFocus
        id={popoverId}
        onClose={handlePopoverClose}
        open={open}
        elevation={4}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {content}
      </Popover>
    </>
  );
};

export default InfoPopover;
