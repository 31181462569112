import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import InfoPopover from 'components/InfoPopover/InfoPopover';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import HeaderContent from 'features/Flow/components/RightSidebar/components/HeaderContent/HeaderContent';
import { PathwayNode } from 'features/Flow/nodes/Node/Node.types';
import { getJobTitle, getJobDescription } from 'utils/neurons';

interface PathwayNodePanelHeaderProps {
  selectedNode: PathwayNode;
}

const PathwayNodePanelHeader = ({ selectedNode }: PathwayNodePanelHeaderProps) => {
  return (
    <>
      <TextEllipsis variant="titleSmall">{getJobTitle(selectedNode.data.metadata)}</TextEllipsis>

      <InfoPopover
        hoverTarget={<InfoRoundedIcon />}
        content={
          <HeaderContent
            type={selectedNode.data.metadata.type}
            title={selectedNode.data.metadata.name}
            description={getJobDescription(selectedNode.data.metadata)}
          />
        }
      />
    </>
  );
};

export default PathwayNodePanelHeader;
