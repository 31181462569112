import { FlowNode, FlowNodeData } from 'types/reactflow';
import { NodeInput } from '../nodes/Node/Node.types';
import { applyNodeUpdateDataChange, createNodeUpdateDataChange } from './NodeUpdateDataChange';

export type NodeInputUpdater<TInput extends NodeInput = NodeInput> = (
  prev: TInput,
) => Partial<TInput>;

export interface NodeUpdateInputChange<TInput extends NodeInput = NodeInput> {
  inputName: string;
  nodeId: string;
  type: 'updateInput';
  updater: NodeInputUpdater<TInput>;
}

export function createNodeUpdateInputChange<TInput extends NodeInput>(
  params: Omit<NodeUpdateInputChange<TInput>, 'type'>,
): NodeUpdateInputChange {
  return {
    type: 'updateInput',
    inputName: params.inputName,
    nodeId: params.nodeId,
    updater: params.updater as unknown as NodeInputUpdater,
  };
}

export function applyNodeUpdateInputChange(change: NodeUpdateInputChange, nodes: FlowNode[]) {
  const { nodeId, inputName, updater } = change;
  const dataChange = createNodeUpdateDataChange<WithInputs<NodeInput>>({
    nodeId,
    updater: (data) => {
      if (!hasInputs<NodeInput>(data)) {
        return data;
      }

      const nextInputs = data.inputs.map((input) => {
        if (input.name !== inputName) {
          return input;
        }

        const updates = updater(input);
        const nextInput: typeof input = {
          ...input,
          ...updates,
        };

        return nextInput;
      });

      return {
        inputs: nextInputs,
      };
    },
  });
  return applyNodeUpdateDataChange(dataChange, nodes);
}

interface WithInputs<TInput> extends FlowNodeData {
  inputs: TInput[];
}

function hasInputs<TInput>(data: Partial<WithInputs<TInput>>): data is WithInputs<TInput> {
  return !!data.inputs;
}
