import { Typography } from '@mui/material';
import BackButton from 'components/BackButton/BackButton';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { FlowPipeline } from 'types/reactflow';
import { useAppRoutes } from 'utils/routes';

interface NewExecutionNavProps {
  pipeline: FlowPipeline;
}

const NewExecutionNav = ({ pipeline }: NewExecutionNavProps) => {
  const { name, id } = pipeline;
  const routes = useAppRoutes();

  return (
    <NavigationBar
      title={name}
      firstBreadcrumb={{
        label: (
          <BackButton to={routes.executions.runs(id)}>
            <Typography variant="titleMedium">New Run</Typography>
          </BackButton>
        ),
      }}
      hideHome={true}
    />
  );
};
export default NewExecutionNav;
