import { Box } from '@mui/material';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import TextEllipsis from 'components/TextEllipsis/TextEllipsis';
import { useStoreApi } from 'reactflow';
import { JobNode } from 'types/reactflow';
import { getJobTitle } from 'utils/neurons';
import * as Styled from './JobElementLayerButton.styles';

interface JobElementLayerButtonProps {
  node: JobNode;
}

export function JobElementLayerButton(props: JobElementLayerButtonProps) {
  const { node } = props;
  const { mode, executionJobs, unselectNodesAndEdges, addSelectedNodes } = useStoreApi().getState();

  const title = getJobTitle({
    id: node.id,
    ...node.data.metadata,
  });
  const type = node.type;
  const iconColor = node.selected ? 'currentcolor' : undefined;
  const execution = executionJobs?.find((job) => job.id === node.id);

  return (
    <Styled.JobElementLayerButton
      $nodeType={type}
      selected={node.selected}
      onClick={() => {
        if (node.selected) {
          unselectNodesAndEdges({
            nodes: [node],
          });
        } else {
          addSelectedNodes([node.id]);
        }
      }}
    >
      {mode !== 'runtime' && <JobTypeIcon type={type} color={iconColor} />}
      <TextEllipsis variant="labelLarge">{title}</TextEllipsis>
      <Box display="flex" marginLeft="auto">
        <StatusIcon status={execution?.status} color={iconColor} />
      </Box>
    </Styled.JobElementLayerButton>
  );
}
