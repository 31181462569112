import { useRequest } from 'api/client';
import { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { PipelinesResponse, UsePipelinesRequest } from './usePipelines.types';

const usePipelines = ({ projectId, workspaceId, searchQuery }: UsePipelinesRequest) => {
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isDebouncing, setIsDebouncing] = useState(false);

  const debouncedSetSearchQuery = useMemo(
    () =>
      debounce((query: string) => {
        setDebouncedSearchQuery(query);
        setIsDebouncing(false);
      }, 400),
    [],
  );

  useEffect(() => {
    if (searchQuery.length === 0) {
      setDebouncedSearchQuery('');
      return;
    }
    if (searchQuery.length < 3) return;
    setIsDebouncing(true);
    debouncedSetSearchQuery(searchQuery);
  }, [searchQuery, debouncedSetSearchQuery]);

  const queryParams = new URLSearchParams();
  if (projectId) {
    queryParams.append('projectId', projectId);
  }
  if (workspaceId) {
    queryParams.append('workspaceId', workspaceId);
  }
  if (debouncedSearchQuery) {
    queryParams.append('searchQuery', debouncedSearchQuery);
  }

  const { isLoading, isValidating, data, error, mutate } = useRequest<PipelinesResponse>({
    url: `/pipelines?${queryParams.toString()}`,
    method: 'GET',
  });

  return {
    debouncedSearchQuery,
    arePipelinesLoading: isLoading || isValidating || isDebouncing,
    pipelines: data?.items ?? [],
    pipelinesError: error,
    mutatePipelines: mutate,
  };
};

export default usePipelines;
