import { Button } from '@mui/material';
import styled from 'styled-components';

export const CopyButton = styled(Button)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
