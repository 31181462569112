import { PipelineExecution } from 'api/services/usePipelineExecution/usePipelineExecution.types';
import Loader from 'components/Loader/Loader';
import { PipelineTriggerOutputAccordion } from './PipelineTriggerOutputAccordion';
import { usePipelineExecutionInputs } from 'api/services/usePipelineExecutionInputs/usePipelineExecutionInputs';
import { ErrorCard } from 'components/ErrorCard/ErrorCard';
import ExecutionOutputByType from '../ExecutionCompleted/ExecutionOutputByType';
import React from 'react';

interface PipelineTriggerOutputProps {
  pipelineExecution: PipelineExecution;
}

/**
 * A specialized component for fetching and rendering
 * the outputs of a pipeline trigger job for an execution.
 */
export function PipelineTriggerOutput({ pipelineExecution }: PipelineTriggerOutputProps) {
  const {
    loadingPipelineExecutionInputs,
    pipelineExecutionInputsError,
    pipelineExecutionInputs,
    mutatePipelineExecutionInputs,
  } = usePipelineExecutionInputs(pipelineExecution.id);

  // the completed at for the pipeline trigger is the start time for the execution.
  const completedAt = pipelineExecution.createdAt;

  if (pipelineExecutionInputsError) {
    return (
      <ErrorCard
        message="An error occurred and the execution inputs could not be loaded."
        onRetry={mutatePipelineExecutionInputs}
      />
    );
  }

  const executionInputs = pipelineExecutionInputs.filter((inp) => inp.value ?? false);

  return (
    <Loader loading={loadingPipelineExecutionInputs}>
      <PipelineTriggerOutputAccordion completedAt={completedAt}>
        {executionInputs.map((executionInput) => {
          return (
            <React.Fragment key={executionInput.id ?? executionInput.name}>
              <ExecutionOutputByType key={executionInput.name} output={executionInput} />
            </React.Fragment>
          );
        })}
      </PipelineTriggerOutputAccordion>
    </Loader>
  );
}
