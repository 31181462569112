import { Divider, Paper } from '@mui/material';
import styled, { css } from 'styled-components';

export const Sidebar = styled(Paper)<{
  $borderLeft?: boolean;
  $borderRight?: boolean;
}>(
  ({ theme, $borderLeft, $borderRight }) => css`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 264px;
    padding: ${theme.spacing(2)};
    overflow-x: hidden;
    overflow-y: auto;

    ${$borderLeft &&
    css`
      border-left: 1px solid ${theme.palette.divider};
    `}
    ${$borderRight &&
    css`
      border-right: 1px solid ${theme.palette.divider};
    `}
  `,
);

export const SectionDivider = styled(Divider)<{
  $addMargin?: boolean;
}>(
  ({ theme, $addMargin = true }) => css`
    ${$addMargin &&
    css`
      margin: ${theme.spacing(2, -2)};
    `};
    border-color: ${theme.palette.divider};

    /* Hide the hr element if it's directly below another hr element */
    & + hr {
      display: none;
    }
  `,
);
