import { IconButton } from '@mui/material';
import { NodeType, isHandleConnection } from 'features/Flow/Flow.types';
import * as Styled from '../../Node/Node.styles';
import { NodeInput, PathwayNode, PathwayNodeProps } from '../../Node/Node.types';
import { getNodeInput, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import { useCallback, useEffect, useMemo } from 'react';
import { useStore, useUpdateNodeInternals } from 'reactflow';
import { useUpdateNodeData } from 'features/Flow/hooks/useUpdateNodeData';
import { FlowState } from 'types/reactflow';
import { useEditorContext } from 'features/Flow/hooks/useEditorContext';
import ObjectBuilderModal from './ObjectBuilderModal/ObjectBuilderModal';
import SettingIcon from 'components/Icons/Setting';
import { NodeHeader } from '../../Node/components/NodeHeader';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { useObjectBuilderModal } from './ObjectBuilderModal/useObjectBuilderModal';
import { useObjectBuilderQuickCreateModal } from './ObjectBuilderQuickCreateModal/useObjectBuilderQuickCreateModal';
import ObjectBuilderQuickCreateModal from './ObjectBuilderQuickCreateModal/ObjectBuilderQuickCreateModal';
import NodeInputHandle from '../../Node/NodeInputHandle';
import NodeOutputHandle from '../../Node/NodeOutputHandle';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import { createCustomHandle, useGetSourceHandle } from 'features/Flow/Handles/handles';
import { canBuilderCustomHandleConnect } from './ObjectBuilder.utils';
import { HandleKey } from 'features/Flow/Handles/handle.store';
import { useDeferredConnectionState } from 'features/Flow/hooks/useDeferredConnectionState';

const selector = ({ mode }: FlowState) => ({
  mode,
});

const ObjectBuilder: React.FC<PathwayNodeProps> = (props) => {
  const {
    id: nodeId,
    data: { metadata, inputs, outputs },
  } = props;

  const updateNodeInternals = useUpdateNodeInternals();
  const updateNodeData = useUpdateNodeData();
  const { addConnectionHandler, removeConnectionHandler } = useEditorContext();
  const { deferredConnection, setDeferredConnectionState, cancelDeferredConnectionAction } =
    useDeferredConnectionState({
      inputs,
    });

  const attemptAddInput = useCallback(
    (input: NodeInput, nodeId: string): boolean => {
      if (inputs.length > 0) {
        const isNewKeyInUse = inputs.some((item) => item.name === input.name);

        if (isNewKeyInUse) {
          return false;
        }
      }

      updateNodeData<PathwayNode['data']>(nodeId, function (data) {
        return { ...data, inputs: [...inputs, input] };
      });

      return true;
    },
    [inputs, updateNodeData],
  );
  const addInputHandle = useMemo(
    () => createCustomHandle(nodeId, 'input', canBuilderCustomHandleConnect),
    [nodeId],
  );
  const getSourceHandle = useGetSourceHandle();

  const createInputFromModal = useCallback(
    (input: NodeInput) => {
      if (!deferredConnection) {
        throw new Error('No deferred connection found.');
      }

      const newInput = getNodeInput(input);
      const isAdded = attemptAddInput(newInput, nodeId);

      if (!isAdded) {
        throw new Error('Input was not added.');
      }

      setDeferredConnectionState({
        connection: {
          ...deferredConnection,
          targetHandle: newInput.id,
        },
        inputName: newInput.name,
      });
    },
    [attemptAddInput, deferredConnection, nodeId, setDeferredConnectionState],
  );

  const objectBuilderModalState = useObjectBuilderModal({
    nodeId,
    inputs,
  });
  const { openModal, ...quickCreateModal } = useObjectBuilderQuickCreateModal({
    onCreateInput: createInputFromModal,
    onCloseModal: cancelDeferredConnectionAction,
  });
  const { mode } = useStore(selector);
  const { execution } = useGetNodeExecution({ id: nodeId });

  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);

  useEffect(() => {
    const connectionHandlerKey: HandleKey = {
      id: addInputHandle.id,
      nodeId,
    };
    addConnectionHandler?.(connectionHandlerKey, (conn, actionCallback) => {
      if (!isHandleConnection(conn)) return conn;

      const sourceHandle = getSourceHandle(conn);
      const newInput = getNodeInput({
        config: { required: true },
        dataSchema: sourceHandle.schema,
        name: sourceHandle.name,
        title: sourceHandle.title,
        description: sourceHandle.description,
      });
      const isAdded = attemptAddInput(newInput, nodeId);

      setDeferredConnectionState({
        action: actionCallback,
        connection: {
          ...conn,
          targetHandle: newInput.id,
        },
      });

      if (isAdded) {
        setDeferredConnectionState({
          inputName: newInput.name,
        });
      } else {
        // Open the modal for the user to finish creating the new input
        openModal(newInput);
      }

      return 'defer';
    });
    return () => {
      removeConnectionHandler?.(connectionHandlerKey);
    };
  }, [
    addConnectionHandler,
    addInputHandle.id,
    attemptAddInput,
    getSourceHandle,
    nodeId,
    openModal,
    removeConnectionHandler,
    setDeferredConnectionState,
  ]);

  useEffect(() => {
    updateNodeInternals(nodeId);
    // 'inputs' is a dependency here because we need to update the node internals when it change.
    inputs;
  }, [inputs, nodeId, updateNodeInternals]);

  return (
    <Styled.NodeContainer
      data-node-id={nodeId}
      $isSelected={props.selected}
      $nodeType={NodeType.FUNCTION}
    >
      <NodeHeader
        metadata={metadata}
        execution={execution}
        icon={
          <>
            {mode === 'runtime' && (
              <StatusIcon
                status={execution?.status}
                color={({ palette }) => palette.text.primary}
              />
            )}
            {mode === 'editor' && (
              <IconButton
                onClick={objectBuilderModalState.openModal}
                data-testid="NodeSettingButton"
              >
                <SettingIcon />
              </IconButton>
            )}
          </>
        }
      />

      <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        <Styled.NodeDataColumn>
          {inputs.map((input) => {
            return <NodeInputHandle key={input.name} input={input} nodeId={nodeId} />;
          })}
          {mode === 'editor' && <CustomHandle title="Add Inputs" handle={addInputHandle} />}
        </Styled.NodeDataColumn>
        <Styled.NodeDataColumn>
          {outputs.map((output) => (
            <NodeOutputHandle key={output.name} output={output} nodeId={nodeId} />
          ))}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>
      {mode === 'editor' && objectBuilderModalState.open && (
        <ObjectBuilderModal inputs={inputs} {...objectBuilderModalState} />
      )}
      {mode === 'editor' && quickCreateModal.open && (
        <ObjectBuilderQuickCreateModal inputs={inputs} {...quickCreateModal} />
      )}
    </Styled.NodeContainer>
  );
};

export default ObjectBuilder;
