import { CheckpointInputValue } from 'api/services/usePipelineExecutionCheckpoints/usePipelineExecutionCheckpoints.types';
import { PropsWithChildren, createContext, useState } from 'react';

type InputValueState = ReturnType<typeof useState<CheckpointInputValue>>;

interface ExecutionCheckpointContext {
  pipelineExecutionId: string;
  contextInputValue: InputValueState[0];
  setContextInputValue: InputValueState[1];
}

export const ExecutionCheckpointContext = createContext<ExecutionCheckpointContext | null>(null);
ExecutionCheckpointContext.displayName = 'ExecutionCheckpointContext';

interface ProviderProps {
  pipelineExecutionId: string;
}

export function ExecutionCheckpointProvider({
  children,
  pipelineExecutionId,
}: PropsWithChildren<ProviderProps>) {
  const [contextInputValue, setContextInputValue] = useState<CheckpointInputValue>();

  return (
    <ExecutionCheckpointContext.Provider
      value={{
        pipelineExecutionId,
        contextInputValue,
        setContextInputValue,
      }}
    >
      {children}
    </ExecutionCheckpointContext.Provider>
  );
}
