import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { NodeInput } from '../../../Node/Node.types';
import { Grid, IconButton, Typography } from '@mui/material';
import * as Styled from './ObjectBuilderModal.styles';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DataSchemaIcon from 'components/DataSchemaIcon/DataSchemaIcon';

interface SortableNodeInputProps {
  input: NodeInput;
  index: number;
  isDragging: boolean;
  onEditInput: (input: NodeInput, position: number) => void;
  onRemoveInput: (index: number) => void;
}

const SortableNodeInput = ({
  index,
  input,
  isDragging,
  onEditInput,
  onRemoveInput,
}: SortableNodeInputProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: input.name,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <Grid container alignItems="center">
        <Grid item xs={1} display="flex" alignItems="center">
          <Styled.DragIcon {...listeners} $isDragging={isDragging} />
        </Grid>
        <Grid item xs={1}>
          <DataSchemaIcon dataSchema={input.dataSchema} size={'24px'} />
        </Grid>
        <Grid item xs={8}>
          <Typography>{input.title || input.name}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              onEditInput(input, index);
            }}
          >
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={() => {
              onRemoveInput(index);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default SortableNodeInput;
