import Node from 'features/Flow/nodes/Node/Node';
import { PathwayNodeProps } from '../Node/Node.types';
import DynamicCheckpoint from './DynamicCheckpoint/DynamicCheckpoint';

const COMPONENT_MAP: Partial<Record<string, React.ComponentType<PathwayNodeProps>>> = {
  'dynamic@v1': DynamicCheckpoint,
};

export default function Checkpoint(props: PathwayNodeProps) {
  const Component = COMPONENT_MAP[props.data.metadata.uses];

  if (Component) {
    return <Component {...props} />;
  }
  return <Node {...props} />;
}
