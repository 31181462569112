import useDeletePipeline from 'api/services/useDeletePipeline/useDeletePipeline';
import usePipelines from 'api/services/usePipelines/usePipelines';
import { useNavigate } from 'react-router-dom';
import { PipelineResponse } from 'types/pipeline';
import { useAppRoutes } from 'utils/routes';
import DeletePipelineDialog from '../DeletePipelineDialog/DeletePipelineDialog';
import useDeletePipelineDialog from '../DeletePipelineDialog/useDeletePipelineDialog';
import PipelineTable, { PipelineTableProps } from '../PipelineTable/PipelineTable';
import useToast from 'contexts/toast/useToast';
import useCopyPipeline from 'api/services/useCopyPipeline/useCopyPipeline';

interface PipelineTableContainerProps
  extends Pick<PipelineTableProps, 'loading' | 'loadingFailed' | 'pipelines'> {
  mutatePipelines: ReturnType<typeof usePipelines>['mutatePipelines'];
}

export default function PipelineTableContainer(props: PipelineTableContainerProps) {
  const { loading, loadingFailed, pipelines, mutatePipelines } = props;

  const navigate = useNavigate();
  const routes = useAppRoutes();
  const toast = useToast();

  const { deletePipeline } = useDeletePipeline();
  const { deletePipelineDialogState, closeDeletePipelineDialog, openDeletePipelineDialog } =
    useDeletePipelineDialog();
  const { copyPipeline } = useCopyPipeline();

  const handleDeletePipelineDialogConfirm = () => {
    const initialData = pipelines.slice();
    const optimisticData = pipelines.filter(
      (pipeline) => pipeline.id !== deletePipelineDialogState.pipelineId,
    );

    void mutatePipelines(
      {
        data: {
          items: optimisticData,
        },
      },
      {
        revalidate: false,
      },
    );

    closeDeletePipelineDialog();
    deletePipeline(deletePipelineDialogState.pipelineId)
      .then(() => {
        toast.success({
          message: 'Pipeline deleted successfully.',
        });
      })
      .catch(() => {
        void mutatePipelines(
          {
            data: {
              items: initialData,
            },
          },
          {
            revalidate: false,
          },
        );

        toast.error({
          message: 'An error occurred while deleting the pipeline.',
        });
      });
  };

  const handleDuplicatePipeline = (pipeline: PipelineResponse) => {
    copyPipeline({ pipelineId: pipeline.id })
      .then(({ data }) => {
        const pipelinesList = pipelines.slice();
        pipelinesList.push(data);

        void mutatePipelines(
          {
            data: {
              items: pipelinesList,
            },
          },
          { revalidate: false },
        );
        toast.success({
          message: 'Pipeline duplicated successfully.',
        });
      })
      .catch(() => {
        toast.error({
          message: 'An error occurred while duplicating the pipeline',
        });
      });
  };

  return (
    <>
      <DeletePipelineDialog
        open={deletePipelineDialogState.open}
        onClose={closeDeletePipelineDialog}
        onConfirm={handleDeletePipelineDialogConfirm}
      />
      <PipelineTable
        loading={loading}
        loadingFailed={loadingFailed}
        pipelines={pipelines}
        onRowClick={(pipelineId) => {
          navigate(routes.executions.runs(pipelineId));
        }}
        onEdit={(pipelineId) => {
          navigate(routes.pipelines(pipelineId));
        }}
        onDuplicate={handleDuplicatePipeline}
        onDelete={openDeletePipelineDialog}
      />
    </>
  );
}
