import useAuthContext from 'contexts/auth/useAuthContext';
import { formatRequiredMessage } from 'utils/message';
import useWorkspaceSlug from './useWorkspaceSlug';

export default function useWorkspace() {
  const { user } = useAuthContext();
  const workspaceSlug = useWorkspaceSlug();

  if (!user) {
    throw new Error(formatRequiredMessage('user'));
  }

  const workspace = user.workspaces.find((workspace) => workspace.slug === workspaceSlug);

  if (!workspace) {
    throw new Error(formatRequiredMessage('workspace'));
  }

  return workspace;
}
