import { useCallback, useMemo, useState } from 'react';
import { TableCell, Checkbox, ClickAwayListener } from '@mui/material';
import { ColumnConfig } from 'components/Table/TableHead';
import { Row } from './useDynamicTable';
import { useFormContext } from 'react-hook-form';
import DynamicTableCell from 'components/DynamicTable/DynamicTableCell';
import * as Styled from 'components/Table/Table.styles';

interface DynamicTableRowProps {
  row: Row;
  columns: ColumnConfig[];
  onSelectRow?: (row: Row) => void;
  onConfirmEditRow: (values: Record<string, unknown>, rowId: string) => void;
}

const DynamicTableRow = ({ row, columns, onSelectRow, onConfirmEditRow }: DynamicTableRowProps) => {
  const initialRowValues = useMemo(() => mapRowToRowValues(row), [row]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowValues, setRowValues] = useState<Record<string, unknown>>(initialRowValues);
  const [autofocusIndex, setAutofocusIndex] = useState(0);
  const { register, unregister } = useFormContext();
  // Used to register/unregister a dummy field in RHF just to trigger validation so approval is disabled when editing
  const RHF_ERROR_FIELDNAME = `rowEdit_${row._id}`;

  const openEditMode = useCallback(() => {
    setIsEditMode(true);
    register(RHF_ERROR_FIELDNAME, { required: true });
  }, [setIsEditMode, register, RHF_ERROR_FIELDNAME]);

  const closeEditMode = useCallback(() => {
    setAutofocusIndex(0);
    setIsEditMode(false);
    unregister(RHF_ERROR_FIELDNAME);
  }, [setIsEditMode, unregister, RHF_ERROR_FIELDNAME]);

  const onCellValueChange = useCallback((columnId: string, value: unknown) => {
    setRowValues((prev) => ({
      ...prev,
      [columnId]: value,
    }));
  }, []);

  const onKeyDown = useCallback(
    (columnIndex: number, key: string) => {
      const isLastColumn = columnIndex === columns.length - 1;

      if (key === 'Enter' || (isLastColumn && key === 'Tab')) {
        onConfirmEditRow(rowValues, row._id);
        closeEditMode();
      }

      if (key === 'Escape') {
        setRowValues(initialRowValues);
        closeEditMode();
      }
    },
    [columns, initialRowValues, row._id, onConfirmEditRow, rowValues, closeEditMode],
  );

  const handleClickAway = useCallback(() => {
    if (isEditMode) {
      onConfirmEditRow(rowValues, row._id);
      closeEditMode();
    }
  }, [isEditMode, onConfirmEditRow, rowValues, row._id, closeEditMode]);

  const onClick = useCallback(
    (columnIndex: number) => {
      openEditMode();
      setAutofocusIndex(columnIndex);
    },
    [setAutofocusIndex, openEditMode],
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styled.TableBodyRow>
        {columns.map((column, index) => (
          <DynamicTableCell
            key={column.id}
            value={rowValues[column.id]}
            column={column}
            columnIndex={index}
            isEditMode={isEditMode}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={index === autofocusIndex}
            onClick={onClick}
            onChange={onCellValueChange}
            onKeyDown={onKeyDown}
          />
        ))}
        {onSelectRow && !isEditMode && (
          <TableCell align="right">
            <Checkbox
              name="selectCheckbox"
              data-testid={`DynamicTableRow__${row._id}`}
              checked={row._selected}
              edge="end"
              sx={{ marginY: -1.5 }}
              onClick={() => {
                onSelectRow(row);
              }}
            />
          </TableCell>
        )}
      </Styled.TableBodyRow>
    </ClickAwayListener>
  );
};

const mapRowToRowValues = (row: Row): Record<string, string> => {
  return Object.keys(row).reduce((acc, cell) => {
    return {
      ...acc,
      [cell]: renderValue(row[cell]),
    };
  }, {});
};

// To be improved later once we understand how to render objects and arrays
const renderValue = (value: unknown) => {
  if ((typeof value === 'object' && value !== null) || Array.isArray(value)) {
    return JSON.stringify(value);
  }

  return value;
};

export default DynamicTableRow;
