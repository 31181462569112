import { useCallback } from 'react';
import { useStoreApi } from 'reactflow';
import {
  NodeInputUpdater,
  NodeUpdateInputChange,
  createNodeUpdateInputChange,
} from '../changes/NodeUpdateInputChange';
import { NodeInput } from '../nodes/Node/Node.types';

type UseUpdateNodeInputParams = Omit<NodeUpdateInputChange, 'type' | 'updater'>;

export function useUpdateNodeInput() {
  const storeApi = useStoreApi();

  return useCallback(
    <TInput extends NodeInput>(
      params: UseUpdateNodeInputParams,
      updater: NodeInputUpdater<TInput>,
    ) => {
      storeApi.getState().onNodesChange?.([
        createNodeUpdateInputChange<TInput>({
          inputName: params.inputName,
          nodeId: params.nodeId,
          updater,
        }),
      ]);
    },
    [storeApi],
  );
}
