import { IconButton } from '@mui/material';
import { getConnectedEdges, useStore } from 'reactflow';
import { NodeType } from 'features/Flow/Flow.types';
import { createHandleId, useSelectNodeOnClick } from 'features/Flow/Flow.utils';
import SettingIcon from 'components/Icons/Setting';
import StatusIcon from 'components/StatusIcon/StatusIcon';
import { PathwayNodeProps } from '../../Node/Node.types';
import { NodeHeader } from '../../Node/components/NodeHeader';
import * as Styled from '../../Node/Node.styles';
import { useGetNodeExecution } from 'features/Flow/hooks/useGetNodeExecution';
import { FlowState } from 'types/reactflow';
import CustomHandle from 'features/Flow/components/NeuronHandle/CustomHandle';
import NodeInputHandle from '../../Node/NodeInputHandle';
import { useDataManipulator } from './useDataManipulator';
import { useDataManipulationModal } from './DataManipulatorModal/useDataManipulationModal';
import DataManipulatorModal from './DataManipulatorModal/DataManipulatorModal';
import NodeOutputHandle from '../../Node/NodeOutputHandle';
import { useMemo } from 'react';
import useFlow from 'features/Flow/hooks/useFlow';
import { shallow } from 'zustand/shallow';

const selector = ({ mode, edges }: FlowState) => ({
  mode,
  edges,
});

const DataManipulator: React.FC<PathwayNodeProps> = (props) => {
  const {
    id: nodeId,
    data: { metadata, inputs, outputs },
  } = props;
  const { getNode } = useFlow();
  const { edges, mode } = useStore(selector, shallow);
  const { execution } = useGetNodeExecution({ id: nodeId });

  const handleSelectNodeOnClick = useSelectNodeOnClick(nodeId);
  const { addInputHandle, addOutputHandle } = useDataManipulator(props);
  const dataManipulationModal = useDataManipulationModal({ nodeId });

  const inputData = inputs.find((input) => input.name === 'data');
  const outputData = outputs.find((output) => output.name === 'data');
  const nodeEdges = useMemo(() => {
    const node = getNode(nodeId);
    if (!node) return [];

    return getConnectedEdges([node], edges);
  }, [edges, getNode, nodeId]);

  const disableInputType = useMemo(
    () => nodeEdges.some((edge) => edge.targetHandle === createHandleId('input', 'data')),
    [nodeEdges],
  );

  const disableOutputType = useMemo(
    () => nodeEdges.some((edge) => edge.sourceHandle === createHandleId('output', 'data')),
    [nodeEdges],
  );

  return (
    <Styled.NodeContainer
      data-node-id={nodeId}
      $isSelected={props.selected}
      $nodeType={NodeType.FUNCTION}
    >
      <NodeHeader
        metadata={metadata}
        execution={execution}
        icon={
          <>
            {mode === 'runtime' && (
              <StatusIcon
                status={execution?.status}
                color={({ palette }) => palette.text.primary}
              />
            )}
            {mode === 'editor' && (
              <IconButton onClick={dataManipulationModal.openModal} data-testid="NodeSettingButton">
                <SettingIcon />
              </IconButton>
            )}
          </>
        }
      />
      <Styled.NodeDataRow className="nodrag" onClick={handleSelectNodeOnClick}>
        <Styled.NodeDataColumn>
          {inputData && <NodeInputHandle key={inputData.id} nodeId={nodeId} input={inputData} />}
          {mode === 'editor' && !inputData && (
            <CustomHandle title="Add Data" handle={addInputHandle} />
          )}
        </Styled.NodeDataColumn>
        <Styled.NodeDataColumn>
          {outputData && (
            <NodeOutputHandle key={outputData.id} nodeId={nodeId} output={outputData} />
          )}
          {mode === 'editor' && !outputData && (
            <CustomHandle title="Add Output" handle={addOutputHandle} />
          )}
        </Styled.NodeDataColumn>
      </Styled.NodeDataRow>
      {mode === 'editor' && dataManipulationModal.open && (
        <DataManipulatorModal
          disableInputType={disableInputType}
          disableOutputType={disableOutputType}
          inputs={inputs}
          outputs={outputs}
          {...dataManipulationModal}
        />
      )}
    </Styled.NodeContainer>
  );
};

export default DataManipulator;
