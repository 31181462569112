import { useRequest } from 'api/client';
import { SubpipelineManifestResponse } from 'api/services/useSearchSubpipelines/useSearchSubpipelines.types';
import { useParams } from 'react-router-dom';

interface UseSearchSubpipelinesRequest {
  projectId?: string;
  query?: string;
}

const useSearchSubpipelines = ({ projectId, query = '' }: UseSearchSubpipelinesRequest) => {
  const { pipelineId } = useParams();
  const subpipelinesRequest = useRequest<SubpipelineManifestResponse[]>({
    url: `/manifests/${projectId}/subpipelines?query=${encodeURIComponent(query)}`,
    method: 'GET',
  });

  return {
    isLoading: subpipelinesRequest.isLoading || subpipelinesRequest.isValidating,
    subpipelineManifests: (subpipelinesRequest.data ?? []).filter(
      (subpipeline) => subpipeline.pipeline.id !== pipelineId,
    ),
    error: subpipelinesRequest.error,
    mutateSubpipelineManifests: subpipelinesRequest.mutate,
  };
};

export default useSearchSubpipelines;
