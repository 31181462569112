import { Box } from '@mui/material';
import styled from 'styled-components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export const DragIcon = styled(DragIndicatorIcon)<{ $isDragging: boolean }>`
  color: ${(props) => props.theme.palette.action.active};
  cursor: ${(props) => (props.$isDragging ? 'grabbing' : 'grab')};
`;

export const SortableBox = styled(Box)<{ $isDragging: boolean }>`
  cursor: ${(props) => (props.$isDragging ? 'grabbing' : 'default')};
`;
