import { Box, Button } from '@mui/material';
import styled from 'styled-components';

export const PipelineActionContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 24px;
`;

export const PipelineActionButton = styled(Button)`
  padding: 6px 16px;
  height: 36px;
` as typeof Button;

export const PipelineSaveButton = styled(Button)`
  padding: 6px 16px;
  height: 36px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 126px;
` as typeof Button;

export const PipelineActionMenuButton = styled(Button)`
  color: ${({ theme }) => theme.palette.primary.main};
  min-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 6px;
  padding-right: 8px;
`;

export const PipelineActionButtonContainer = styled(Box)`
  padding: 0;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
`;
