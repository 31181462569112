import { SvgIcon, SvgIconProps } from '@mui/material';

const ImageArrayIcon: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7.28571 18C6.93214 18 6.62946 17.872 6.37768 17.6161C6.12589 17.3601 6 17.0595 6 16.7143V7.28571C6 6.94048 6.12589 6.63988 6.37768 6.38393C6.62946 6.12798 6.93214 6 7.28571 6H16.7143C17.0679 6 17.3705 6.12798 17.6223 6.38393C17.8741 6.63988 18 6.94048 18 7.28571V16.7143C18 17.0595 17.8741 17.3601 17.6223 17.6161C17.3705 17.872 17.0679 18 16.7143 18H7.28571ZM8.78571 15.4286H15.2178C15.3464 15.4286 15.4405 15.369 15.5 15.25C15.5595 15.131 15.5476 15.0179 15.4643 14.9107L13.5357 12.3393C13.4732 12.244 13.3899 12.1964 13.2857 12.1964C13.1815 12.1964 13.0982 12.244 13.0357 12.3393L11.3571 14.5714L10.3214 13.1964C10.2589 13.1012 10.1756 13.0536 10.0714 13.0536C9.96726 13.0536 9.88393 13.1012 9.82143 13.1964L8.53896 14.9112C8.45346 15.018 8.44196 15.131 8.50446 15.25C8.56696 15.369 8.66071 15.4286 8.78571 15.4286Z"
          fill="black"
        />
        <path
          d="M1.875 21.125C2.45833 21.7083 3.16667 22 4 22H6C6.28333 22 6.52083 21.9042 6.7125 21.7125C6.90417 21.5208 7 21.2833 7 21C7 20.7167 6.90417 20.4792 6.7125 20.2875C6.52083 20.0958 6.28333 20 6 20H4C3.71667 20 3.47917 19.9042 3.2875 19.7125C3.09583 19.5208 3 19.2833 3 19V5C3 4.71667 3.09583 4.47917 3.2875 4.2875C3.47917 4.09583 3.71667 4 4 4H6C6.28333 4 6.52083 3.90417 6.7125 3.7125C6.90417 3.52083 7 3.28333 7 3C7 2.71667 6.90417 2.47917 6.7125 2.2875C6.52083 2.09583 6.28333 2 6 2H4C3.16667 2 2.45833 2.29167 1.875 2.875C1.29167 3.45833 1 4.16667 1 5V19C1 19.8333 1.29167 20.5417 1.875 21.125Z"
          fill="black"
        />
        <path
          d="M22.125 21.125C21.5417 21.7083 20.8333 22 20 22H18C17.7167 22 17.4792 21.9042 17.2875 21.7125C17.0958 21.5208 17 21.2833 17 21C17 20.7167 17.0958 20.4792 17.2875 20.2875C17.4792 20.0958 17.7167 20 18 20H20C20.2833 20 20.5208 19.9042 20.7125 19.7125C20.9042 19.5208 21 19.2833 21 19V5C21 4.71667 20.9042 4.47917 20.7125 4.2875C20.5208 4.09583 20.2833 4 20 4H18C17.7167 4 17.4792 3.90417 17.2875 3.7125C17.0958 3.52083 17 3.28333 17 3C17 2.71667 17.0958 2.47917 17.2875 2.2875C17.4792 2.09583 17.7167 2 18 2H20C20.8333 2 21.5417 2.29167 22.125 2.875C22.7083 3.45833 23 4.16667 23 5V19C23 19.8333 22.7083 20.5417 22.125 21.125Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
};

export default ImageArrayIcon;
