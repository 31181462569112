import { NavigateBefore } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const BeforeIconBox = styled(Box)`
  background-color: ${({ theme }) => theme.palette.action.selected};
  border-radius: ${({ theme }) => theme.spacing(1)};
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const PipelineVersionsLink = styled(Link)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
`;

const BackButton = (props: LinkProps) => {
  const { children, ...rest } = props;
  return (
    <PipelineVersionsLink {...rest}>
      <Stack direction="row" alignItems="center">
        <BeforeIconBox>
          <NavigateBefore />
        </BeforeIconBox>
        {children}
      </Stack>
    </PipelineVersionsLink>
  );
};

export default BackButton;
