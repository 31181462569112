import { Link, useNavigate } from 'react-router-dom';
import { Layout, MuiContainer, ScrollContainer } from 'components/Layout/Layout.styles';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { useAppRoutes } from 'utils/routes';
import { Button, Stack, Typography } from '@mui/material';
import usePipelineId from 'hooks/usePipelineId';
import { usePipeline } from 'api/services/usePipeline/usePipeline';
import usePipelineVersions from 'api/services/usePipelineVersions/usePipelineVersions';
import PipelineVersionsTable from 'pages/PipelineVersionsPage/components/PipelineVersionsTable/PipelineVersionsTable';
import NavTabs, { NavTabsItems } from 'components/NavTabs/NavTabs';
import { matchesSomeNavTab } from 'components/NavTabs/NavTabs.utils';

export default function PipelineVersionsPage() {
  const pipelineId = usePipelineId();
  const { pipeline } = usePipeline(pipelineId);
  const { pipelineVersionsData, arePipelineVersionsLoading, pipelineVersionsError } =
    usePipelineVersions(pipelineId);
  const navigate = useNavigate();
  const routes = useAppRoutes();

  const handleRowClick = (pipelineVersionId: string) => {
    console.log('Row clicked', pipelineVersionId);
  };

  const handleEditDraft = () => {
    navigate(routes.pipelines(pipelineId));
  };

  const handleViewVersion = (pipelineVersionId: string) => {
    navigate(routes.pipelineVersion(pipelineId, pipelineVersionId));
  };
  const navTabs: NavTabsItems = [
    {
      key: 'runs',
      label: 'Runs',
      to: routes.executions.runs(pipelineId),
    },
    {
      key: 'versions',
      label: 'Versions',
      to: routes.versions(pipelineId),
    },
  ];
  const displayNavTabs = matchesSomeNavTab(navTabs);

  return (
    <Layout>
      <NavigationBar
        title={pipeline?.name ?? 'Pipeline'}
        firstBreadcrumb={{
          label: pipeline?.name ?? 'Pipeline',
          route: routes.pipelines(pipelineId),
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {displayNavTabs && <NavTabs items={navTabs} />}

          <Button
            component={Link}
            color="primary"
            variant="contained"
            to={routes.pipelines(pipelineId)}
          >
            Edit Pipeline
          </Button>
        </Stack>
      </NavigationBar>

      <ScrollContainer>
        <MuiContainer fixed>
          <Typography
            sx={{
              paddingBottom: 3,
            }}
            component="h1"
            variant="titleLarge"
          >
            Versions
          </Typography>
          <PipelineVersionsTable
            loading={arePipelineVersionsLoading}
            loadingFailed={!!pipelineVersionsError}
            pipelineVersions={pipelineVersionsData?.items ?? []}
            onRowClick={handleRowClick}
            onEditDraft={handleEditDraft}
            onViewVersion={handleViewVersion}
          />
        </MuiContainer>
      </ScrollContainer>
    </Layout>
  );
}
