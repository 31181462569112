import { ExecutionCheckpointFormProps } from '../../ExecutionCheckpointForm.types';
import * as Styled from '../../CheckpointForm.styles';
import * as BaseStyled from '../../ExecutionCheckpoint.styles';
import { Stack, Typography, Switch, FormControlLabel, useTheme } from '@mui/material';
import { objectValidation } from 'components/ui/rhf-components/utils/validationRules';
import { useController } from 'react-hook-form';
import JobTypeIcon from 'components/JobTypeIcon/JobTypeIcon';
import { NodeType } from 'features/Flow/Flow.types';
import { useState } from 'react';
import DynamicTable from 'components/DynamicTable/DynamicTable';
import ExecutionCheckpointObjectListJsonEditor from './ExecutionCheckpointObjectListJsonEditor';
import ViewSwitchConfirmationModal from './ViewSwitchConfirmationModal';
import { NodeObjectValue } from 'features/Flow/nodes/Node/Node.types';
import { getJobName, getJobTitle } from 'utils/neurons';

const ExecutionCheckpointObjectListForm = ({
  disabled,
  input,
  pendingCheckpoint,
  pendingCheckpointHeader,
}: ExecutionCheckpointFormProps) => {
  const { metadata } = pendingCheckpoint;
  const initialValue = pendingCheckpoint.data.inputs[input.name] as NodeObjectValue[];

  const controller = useController<Record<string, NodeObjectValue[]>>({
    name: input.name,
    rules: {
      required: true,
      ...objectValidation,
    },
  });
  const { field } = controller;
  const { onChange: controllerOnChange } = field;

  const theme = useTheme();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [editAsJson, setEditAsJson] = useState(false);

  const toggleView = () => {
    setEditAsJson((prev) => !prev);
  };

  const handleViewSwitch = () => {
    setShowConfirmModal(true);
  };

  const confirmViewSwitch = () => {
    setShowConfirmModal(false);
    // Set delay so the modal has time to close then switch the view
    setTimeout(() => {
      toggleView();
    }, theme.transitions.duration.short);
  };

  return (
    <Styled.FormContainer>
      <Stack direction="row" justifyContent="space-between" marginBottom={2}>
        <Stack direction="row" gap={3} alignItems="center">
          <Stack direction="row" gap={1} alignItems="center">
            <JobTypeIcon type={NodeType.CHECKPOINT} variant="filled" />
            <Typography variant="titleMedium">{getJobTitle(metadata)}</Typography>
          </Stack>

          <FormControlLabel
            control={<Switch checked={editAsJson} onChange={handleViewSwitch} />}
            label={<Typography variant="bodyMedium">Edit as JSON</Typography>}
          />
        </Stack>
        <BaseStyled.CheckpointApprovalHeaderPill>
          <Typography variant="bodySmall">
            {getJobName(metadata)} <span>|</span> {pendingCheckpointHeader}
          </Typography>
        </BaseStyled.CheckpointApprovalHeaderPill>
      </Stack>

      {editAsJson ? (
        <ExecutionCheckpointObjectListJsonEditor
          disabled={disabled}
          controller={controller}
          initialValue={initialValue}
        />
      ) : (
        <DynamicTable objects={initialValue} selectable onChange={controllerOnChange} />
      )}
      <ViewSwitchConfirmationModal
        open={showConfirmModal}
        view={editAsJson ? 'json' : 'table'}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={confirmViewSwitch}
      />
    </Styled.FormContainer>
  );
};

export default ExecutionCheckpointObjectListForm;
